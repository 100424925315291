import React from 'react';
import { Link } from "gatsby"

// components
// import Button from '../button/Button';

// svgs
// import Burger from '../../svgs/burger.svg';
import Lockup from '../../svgs/lockup.svg';

// styles
import "./header.scss";

export default function Header(props) {
  const { 
    toggleMenu,
    menuOn
  } = props;

  const burger = <div className={`menu__open button button--white bold uppercase`} role={`button`} tabIndex={0} onClick={toggleMenu} onKeyDown={toggleMenu}>Menu</div>
  const close = <div onClick={toggleMenu} onKeyDown={toggleMenu} role={`button`} tabIndex={0} className={`menu__close button button--white`}>X</div>

  return (
    <div className={`header flex justify-between align-center z-2`}>

      <Link to="/" className={`z-2`}>
        <Lockup className={`header__lockup`} />
      </Link>

      { menuOn ? close : burger }

      <ul className={`flex z-2 no-tablet`}>
        <Link to={`/films`} activeClassName="header__item--active" partiallyActive={true} className={`header__item button bold button--white uppercase`}>Films</Link>
        <Link to={`/series`} activeClassName="header__item--active" partiallyActive={true} className={`header__item button bold button--white uppercase`}>Series</Link>
        <Link to={`/books`} activeClassName="header__item--active" partiallyActive={true} className={`header__item button bold button--white uppercase`}>Books</Link>
        <Link to={`/about`} activeClassName="header__item--active" className={`header__item button bold button--white uppercase`}>About</Link>
      </ul>
    </div>
  )
}