import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from "gatsby"

// components

// styles
import "./menu.scss";

export default function Menu(props) {
  const { menu } = props;

  return (
    <CSSTransition
      classNames="menu-transition"
      in={ menu }
      appear
      unmountOnExit
      timeout={ 350 }>
      <div className={`menu z-1`}>
        <Link to={`/films`} className={`menu__item button button--white`}>Films</Link>
        <Link to={`/series`} className={`menu__item button button--white`}>Series</Link>
        <Link to={`/books`} className={`menu__item button button--white`}>Books</Link>
        <Link to={`/about`} className={`menu__item button button--white`}>About</Link>
      </div>
    </CSSTransition>
  )
}