import React, { useState } from "react";

// components
import Footer from "../footer/Footer";
import Header from "../header/Header";
import Menu from "../menu/Menu";

export default function Layout({ children }) {
  const [menuOn, setMenuOn] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false);

  const toggleMenu = () => {
    if (menuOn) {
      setMenuOn(false);
      // setModalOpen(false);
    } else {
      setMenuOn(true);
      // setModalOpen(true);
    }
  }

  return (
    <>      
      <Header 
        toggleMenu={ toggleMenu }
        menuOn={ menuOn } />

      <Menu menu={ menuOn } />
            
      <div className={`view-wrapper`}>
        {children}
      </div>
      <Footer />
    </>
  )
}