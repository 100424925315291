import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

// components
import Newsletter from '../newsletter/Newsletter';

// svgs 

// styles
import "./footer.scss";

export default function Footer(props) {
  let data = useStaticQuery(
    graphql`{
      craft {
        globalSets {
          ... on Craft_footerContent_GlobalSet {
            plainText
          }
        }
      }
    }`
  );

  // console.log('footer data: ', data.craft.globalSets[1].plainText);

  const path = typeof window !== 'undefined' ? window.location.href : '';
  const noBorder = path.indexOf('books') > 0 || path.indexOf('films') > 0 || path.indexOf('series') > 0 ? true : false;

  const year = new Date().getFullYear()

  return (
    <div className={`footer flex justify-between align-center ${noBorder ? 'footer--no-border' : null}`}>
      <div className={`footer__list flex`}>
        <p className={`sans footer__item bold`}>{`© ${year} Temple Hill`}</p>
        <Link to={`/privacy-policy`} className={`footer__item button bold`}>Privacy Policy</Link>
        <Link to={`/terms-of-use`} className={`footer__item button bold`}>Terms of Use</Link>
      </div>
      <Newsletter copy={data.craft.globalSets[1].plainText} />
    </div>
  )
}