import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

// styles
import "./newsletter.scss";

export default function Newsletter(props) {
  const { copy } = props;

  const handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(e.target.email.value).then(data => {
      e.target.outerHTML = "<p class='small'>" + data.msg + "</p>";
    });
  }

  return (
    <div className={`newsletter align-center ${copy ? 'grid grid--2' : null}`}>
      {copy ? <p className={`tiny`}>{copy}</p> : null}

      <form className={`newsletter__form flex align-center`} method="POST" onSubmit={handleSubmit} acceptCharset="UTF-8">
        <input className={`input tiny bold uppercase`} name="email" type="email" placeholder="email" required />
        <input className={`button tiny bold `} type="submit" value="Submit" />
      </form>
    </div>
  )
}
